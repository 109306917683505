import { createSlice } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";
import { LOCAL_STORAGE_FILTERS } from "../globals/constants";
import generateFilterQueryString from "../services/filterService";
import {
  getFromLocalStorage,
  saveToLocalStorage,
} from "../services/localStorageService";

const localStorageFiltersByIndex = getFromLocalStorage(LOCAL_STORAGE_FILTERS);
export type FilterState = {
  forIndex: {
    [key: string]: {
      value: [];
      sortBy: { column: string; order: string };
      limit: number;
      page: number;
      filterParams: string;
      auditLog?: {
        [key: string]: {
          operations: string[];
          fields: string[];
        };
      };
      dashboard?: {};
    };
  };
};
/**
 * Generates a default filter state object with optional additional keys.
 * @param {Object} [additionalKeys]
 * An optional object containing additional key-value pairs to be
 *  merged with the base filter state.
 * @returns {Object}
 * A new object containing the default filter state merged
 * with any additional keys provided.
 */
export const generateDefaultFilterState = (additionalKeys?: {
  [key: string]: any;
}) => {
  const baseKeys = {
    value: [],
    sortBy: { column: "", order: "" },
    limit: 50,
    page: 1,
    filterParams: "",
  };
  return {
    ...baseKeys,
    ...additionalKeys,
  };
};

const defaultExclusions = {
  project_workers: {
    operations: ["Insert", "Update", "Delete"],
    fields: [],
  },
  project_dates: {
    operations: ["Insert", "Update", "Delete"],
  },
  projects: {
    operations: ["Insert", "Update", "Delete"],
  },
  project_invites: {
    operations: ["Insert", "Update", "Delete"],
  },
  shift_invites: {
    operations: ["Insert", "Update", "Delete"],
  },
};

/**
 * Initializes and returns the filter state for entities in the application.
 * This function checks the locally stored filters against a
 *  list of required keys,adds missing keys with default values,
 * and updates local storage if changes are made.
 * @returns {FilterState}
 * An object containing the initial filter state for all required entities.
 */
const getInitialFilterState = (): FilterState => {
  const requiredKeys = [
    "auditlog",
    "autoSchedulers",
    "dashboard",
    "foremen",
    "users",
    "markets",
    "manufacturers",
    "workerTypes",
    "siteRequirements",
    "businesses",
    "workers",
    "productLines",
    "projects",
    "projectDates",
    "projectInvites",
    "projectSiteRequirements",
    "projectWorkers",
    "shiftInvites",
    "timeLogs",
    "workerBlackLists",
    "uploads",
  ];
  let storedFilters = localStorageFiltersByIndex || { forIndex: {} };
  let missingKeys = false;
  const storedKeys = Object.keys(storedFilters.forIndex);
  requiredKeys.forEach((rKey) => {
    if (storedKeys.indexOf(rKey) === -1) {
      missingKeys = true;
      const additionalKeys =
        rKey === "auditlog"
          ? { logAlertExclusions: defaultExclusions }
          : rKey === "dashboard"
          ? { localSortColumns: {} }
          : undefined;
      storedFilters.forIndex[rKey] = generateDefaultFilterState(additionalKeys);
    }
  });
  if (missingKeys) {
    saveToLocalStorage(LOCAL_STORAGE_FILTERS, storedFilters);
  }
  return storedFilters;
};

const filterSlice = createSlice({
  name: "filters",
  initialState: getInitialFilterState(),
  reducers: {
    /**
     * Adds a new filter to the state for a specific index
     * @param {any} state
     * The current state object
     * @param {object} action
     * The action object containing payload
     * @param {object} action.payload
     * The payload of the action
     * @param {string} action.payload.index
     * The index to add the filter to
     * @param {object} action.payload.newFilter
     * The new filter to be added
     * @param {string} action.payload.newFilter.column
     * The column to filter on
     * @param {string} action.payload.newFilter.operator
     * The operator for the filter
     * @param {string} action.payload.newFilter.pattern
     * The pattern to match in the filter
     * @returns {void}
     * This method doesn't return a value, it mutates the state directly
     */
    addIndexFilter: (state: any, action) => {
      const { index, newFilter } = action.payload;
      const oldStateForIndex =
        state.forIndex[index] || generateDefaultFilterState();
      //Don't add duplicate filters
      if (
        oldStateForIndex.value.find(
          (item: any) =>
            item.column === newFilter.column &&
            item.operator === newFilter.operator &&
            item.pattern === newFilter.pattern
        )
      ) {
        return;
      }
      const newStateForIndex = {
        ...oldStateForIndex,
        value: [
          ...oldStateForIndex.value,
          {
            id: uuidv4(),
            ...newFilter,
          },
        ],
      };
      state.forIndex[index] = newStateForIndex;
      state.forIndex[index].filterParams = generateFilterQueryString(
        state.forIndex[index]
      );
    },
    replaceLocalSort: (state: any, action) => {
      const { index, localSortColumns } = action.payload;
      const updatedState =
        state.forIndex[index] ||
        generateDefaultFilterState({ localSortColumns: {} });
      updatedState["localSortColumns"] = localSortColumns;
      state.forIndex[index] = updatedState;
    },
    /**
     * Removes a specific filter from the state for a given index
     * @param {Object} state
     * The current state object
     * @param {Object} action
     * The action object containing payload information
     * @param {Object} action.payload
     * The payload of the action
     * @param {string|number} action.payload.index
     * The index to remove the filter from
     * @param {string|number} action.payload.idToRemove
     * The ID of the filter to remove
     * @returns {void}
     * This method modifies the state directly and doesn't return a value
     */
    removeIndexFilter: (state: any, action) => {
      const { index, idToRemove } = action.payload;
      const updatedState =
        state.forIndex[index] || generateDefaultFilterState();
      updatedState.value = updatedState.value.filter(
        (filter: any) => filter.id !== idToRemove
      );
      updatedState.filterParams = generateFilterQueryString(updatedState);
      state.forIndex[index] = updatedState;
    },
    addOrReplaceSort: (state: any, action) => {
      const { index, newSort } = action.payload;
      const updatedState =
        state.forIndex[index] || generateDefaultFilterState();
      updatedState["sortBy"] = {
        column: newSort.column,
        order: newSort.order,
      };
      state.forIndex[index] = updatedState;
      state.forIndex[index].filterParams = generateFilterQueryString(
        state.forIndex[index]
      );
    },
    /**
     * Updates the page state for a specific index in the filter state.
     * @param {Object} state
     * The current state object.
     * @param {Object} action
     * The action object containing payload information.
     * @param {Object} action.payload
     * The payload of the action.
     * @param {number} action.payload.index
     * The index of the filter state to update.
     * @param {number} [action.payload.page]
     * The new page number (defaults to 1 if not provided).
     * @returns {void}
     * This method does not return a value, it updates the state directly.
     */
    changePage: (state: any, action) => {
      const { index, page } = action.payload;
      const updatedState =
        state.forIndex[index] || generateDefaultFilterState();
      updatedState.page = page || 1;
      updatedState.filterParams = generateFilterQueryString(updatedState);
    },
    setLimit: (state: any, action) => {
      const { index, limit } = action.payload;
      const updatedState =
        state.forIndex[index] || generateDefaultFilterState();
      updatedState.limit = limit;
      updatedState.filterParams = generateFilterQueryString(updatedState);
    },
    //For auditlog only
    addOrReplaceExclusions: (state: any, action) => {
      const { index, exclusions } = action.payload;
      if (index !== "auditlog") {
        return;
      }
      const updatedState =
        state.forIndex[index] ||
        generateDefaultFilterState({ logAlertExclusions: {} });
      updatedState.logAlertExclusions = exclusions;
    },
  },
});

export const {
  addIndexFilter,
  replaceLocalSort,
  removeIndexFilter,
  addOrReplaceSort,
  changePage,
  setLimit,
  addOrReplaceExclusions,
} = filterSlice.actions;
export default filterSlice.reducer;
