import React, { useEffect } from "react";
import { AppRootContainer } from "./AppRoot.styles";
import { AppWrapper } from "../AppWrapper/AppWrapper";
import { getMessageData } from "../../globals/pushNotificationProcessor";
import { useAppSelector } from "../../store/hooks";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const AppRoot = () => {
  const exclusions = useAppSelector(
    (state: any) =>
      (state.filters.forIndex["auditlog"] || {}).logAlertExclusions || {}
  );

  useEffect(() => {
    const broadcaster = new BroadcastChannel("pushNotificationsChannel");
    broadcaster.onmessage = (event) => {
      const data = event.data;
      //At present the only events we have are audit logs
      //but we want to allow the possibility of other notifications
      const messageData = getMessageData(data, exclusions);
      if (messageData) {
        toast(messageData);
      }
    };
  }, []);
  return (
    <AppRootContainer>
      <AppWrapper />
      <ToastContainer />
    </AppRootContainer>
  );
};
