export const tryParseJSONObject = (jsonString: string) => {
  try {
    let o = JSON.parse(jsonString);
    if (o && typeof o === "object") {
      return o;
    }
  } catch (e) {}
  return false;
};

export const capitalizeFirst = (input: string): string => {
  if (!input.length) return input;
  return `${input[0].toUpperCase()}${input.substring(1)}`;
};

export const snakeToSeparateWords = (
  input: string,
  capitalizeFirstWord: boolean = true,
  capitalizeAllWords: boolean = false
): string => {
  let output = input.split("_");
  if (capitalizeAllWords) {
    output = output.map((word) => capitalizeFirst(word));
  } else if (capitalizeFirstWord) {
    output[0] = capitalizeFirst(output[0]);
  }
  return output.join(" ");
};

export const isNumeric = (value: string) => /^[+-]?\d+(\.\d+)?$/.test(value);
