import { API_ROOT } from "../globals/constants";
import { store } from "../store";
import { protectedFetch } from "./coreApiService";

/**
 * Looks up worker types based on a search term
 * @param {string} searchTerm - The search term to filter worker types
 * @returns {Promise<any>}
 * A promise that resolves to the worker types matching the search term
 */
export const lookupWorkerTypes = async (searchTerm: string) =>
  await protectedFetch(`${API_ROOT}/lookup/workerTypes?term=${searchTerm}`);

/**
 * Retrieves worker types based on the current filter state.
 * @returns {Promise<Object>}
 * A promise that resolves to the worker types data fetched from the API.
 */
export const getWorkerTypes = async () => {
  const state = store.getState();
  const { filterParams } = state.filters.forIndex.workerTypes;
  let filterString = filterParams ? `?${filterParams}` : "";
  return await protectedFetch(`${API_ROOT}/workerTypes${filterString}`);
};

/**
 * Retrieves a worker type by its ID from the API
 * @param {string} workerTypeId - The unique identifier of the worker type
 * @returns {Promise<object>} A promise that resolves to the worker type object
 */
export const getWorkerTypeById = async (workerTypeId: string) =>
  await protectedFetch(`${API_ROOT}/workerTypes/${workerTypeId}`);

/**
 * Creates a new worker type by sending a POST request to the API.
 * @param {Object} newWorkerType - The new worker type object to be created.
 * @returns {Promise<Response>}
 * The response from the API after creating the worker type.
 */
export const createWorkerType = async (newWorkerType: any) => {
  return await protectedFetch(`${API_ROOT}/workerTypes`, {
    method: "POST",
    headers: {
      "Access-Control-Allow-Origin":
        "https://si-admin-backend-uw2o.zeet-steady-install.zeet.app",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(newWorkerType),
  });
};

/**
 * Updates a worker type with the given ID using the provided updates.
 * @param {number}
 *  id - The ID of the worker type to update.
 * @param {any}
 * updates - An object containing the updates to apply to the worker type.
 * @returns {Promise<Response>}
 * A promise that resolves to the fetch Response object.
 */
export const updateWorkerType = async (id: number, updates: any) => {
  return await protectedFetch(`${API_ROOT}/workerTypes/${id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin":
        "https://si-admin-backend-uw2o.zeet-steady-install.zeet.app",
    },
    body: JSON.stringify(updates),
  });
};

/**
 * Deletes a worker type with the specified ID.
 * @param {number}
 * id - The unique identifier of the worker type to delete.
 * @returns {Promise<Response>}
 * A promise that resolves to the response from the API.
 * @throws {Error}
 * If the network request fails or the server returns an error.
 */
export const deleteWorkerType = async (id: number) => {
  return await protectedFetch(`${API_ROOT}/workerTypes/${id}`, {
    method: "DELETE",
  });
};
