import { API_ROOT } from "../globals/constants";
import { protectedFetch } from "./coreApiService";

export const subscribe = async (subscription: any) => {
  return await protectedFetch(`${API_ROOT}/pushNotifications/subscribe`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "https://localhost:3001",
    },
    body: JSON.stringify(subscription),
  }).catch((err: any) => {
    console.error(`Error subscribing to push notifications ${err}`);
  });
};
