import React from "react";
import { BreadCrumbsContainer } from "./BreadCrumbs.styles";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import { NavLink, useLocation } from "react-router-dom";
//comment to force build
export const BreadCrumbs = () => {
  const location = useLocation();
  const { pathname } = location;
  const segments = pathname === "/" ? [""] : pathname.split("/");
  let url = "";
  const breadcrumbLinks = segments.map((segment, i) => {
    if (segment !== "") {
      url += "/";
    }
    url += `${segment}`;
    const linkText = i === 0 ? `home` : `${segment}`;
    return (
      <BreadcrumbItem key={i}>
        <NavLink to={`${window.location.origin}${url}`}>{linkText}</NavLink>
      </BreadcrumbItem>
    );
  });
  return (
    <BreadCrumbsContainer>
      <Breadcrumb>{breadcrumbLinks}</Breadcrumb>
    </BreadCrumbsContainer>
  );
};
