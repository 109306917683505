//@ts-nocheck
import React from "react";
import { Table } from "@radix-ui/themes";
import TableColumnHeader from "./TableColumnHeader";

export type TableComponentProps = {
  id?: string;
  headers: any[];
  body: any[][];
  onSortChange?: () => {};
};

const getHeaderRows = (props: TableComponentProps) => {
  return props.headers.map((headerCellContent, index) => {
    const { key, ...content } = headerCellContent;
    return (
      <Table.ColumnHeaderCell key={`item-${index}`}>
        <TableColumnHeader {...content} />
      </Table.ColumnHeaderCell>
    );
  });
};

const getBodyRows = (props: TableComponentProps) => {
  return props.body.map((cells, index) => (
    <Table.Row key={`item-${index}`}>
      {cells?.map((cell, index) => {
        return index === 0 ? (
          <Table.RowHeaderCell key={`item-${index}`}>
            {cell}
          </Table.RowHeaderCell>
        ) : (
          <Table.Cell key={`item-${index}`}>{cell}</Table.Cell>
        );
      })}
    </Table.Row>
  ));
};

const TableComponent = (props: TableComponentProps) => (
  <Table.Root className="TableRoot">
    <Table.Header>
      <Table.Row>{getHeaderRows(props)}</Table.Row>
    </Table.Header>
    <Table.Body>{getBodyRows(props)}</Table.Body>
  </Table.Root>
);

export default TableComponent;
