import React, { useEffect, useState } from "react";
import { FilterBar } from "../FilterBar/FilterBar";
import { AuditLog } from "../../models/AuditLog";
import TableComponent, { TableComponentProps } from "../Table/Table";
import { PaginationBar } from "../PaginationBar/PaginationBar";
import { useAppSelector } from "../../store/hooks";
import { getLogEntries } from "../../services/auditLogService";
import { LogAlertSettings } from "./LogAlertSettings";
import {
  LogViewerContainer,
  LogTableContainer,
  LogTableScrollingContainer,
  LogTableHeader,
  LogAlertSettingsTitle,
  LogAlertSettingsFooter,
  LogActivityTruncationWarning,
} from "./LogViewer.styles";
import BigSpinner from "../BigSpinner/BigSpinner";
export type LogEntry = {
  id: number;
  tableName: string;
  projectId: number;
  operation: string;
  timestamp: string;
  userId: string;
  previousValue: string;
  newValue: string;
  project: {
    projectName: string;
  };
};

export type GetLogEntriesResult = {
  totalCount: number;
  value: LogEntry[];
};
export const LogViewer = () => {
  const [results, setResults] = useState<LogEntry[]>([]);
  const [resultCount, setResultCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const page = useAppSelector(
    (state: any) => state.filters.forIndex["auditlog"]?.page || 1
  );
  const limit = useAppSelector(
    (state: any) => state.filters.forIndex["auditlog"]?.limit || 20
  );
  const filters = useAppSelector(
    (state: any) => state.filters.forIndex["auditlog"]?.value || []
  );
  useEffect(() => {
    setLoading(true);
    getLogEntries()
      .then((result: GetLogEntriesResult) => {
        setResultCount(result.totalCount);
        setResults(result.value);
      })
      .catch((err) => {
        console.log(`Fetch of logs failed ${err}`);
      })
      .finally(() => setLoading(false));
  }, [filters, page]);

  const tableColumnHeadings = [
    "Timestamp",
    "Table",
    "Project",
    "Operation",
    "User",
    "Activity",
  ];
  const getProjectName = (entry: LogEntry) => {
    return `${entry.project?.projectName} (${entry.projectId})`;
  };

  //This is required because the logging script may truncate
  //the stringified previous and new values
  //In this case the last entry may be incomplete and
  //the closing bracket will be missing
  const validateJSONString = (input: string): string => {
    let truncated = false;
    let correctedInput = input;
    if (correctedInput.lastIndexOf("}") !== correctedInput.length - 1) {
      if (correctedInput.lastIndexOf(":") < correctedInput.lastIndexOf(",")) {
        correctedInput = correctedInput.substring(
          0,
          correctedInput.lastIndexOf(",")
        );
        truncated = true;
      }
      if (
        correctedInput.substring(
          correctedInput.lastIndexOf(":") + 1,
          correctedInput.lastIndexOf(":") + 2
        ) === '"'
      ) {
        correctedInput = `${correctedInput}"`;
      }
      if (truncated) {
        correctedInput = `${correctedInput}, "truncated": true`;
      }
      correctedInput = `${correctedInput}}`;
    }
    return correctedInput;
  };

  const getActivity = (entry: LogEntry) => {
    const oldValue = entry.previousValue
      ? JSON.parse(validateJSONString(entry.previousValue))
      : {};
    const newValue = entry.newValue
      ? JSON.parse(validateJSONString(entry.newValue))
      : {};
    const keys =
      entry.operation === "D" ? Object.keys(oldValue) : Object.keys(newValue);
    const changes: (string | undefined)[] = keys
      .map((key: string) => {
        if (oldValue[key] !== newValue[key] || key === "id") {
          if (entry.operation === "U") {
            return key === "id"
              ? `${key}: ${oldValue[key]}`
              : `${key}: ${oldValue[key]} -> ${newValue[key]}`;
          } else if (entry.operation === "I") {
            return `${key}: ${newValue[key]}`;
          } else if (entry.operation === "D") {
            return `${key}: ${oldValue[key]}`;
          }
        } else return undefined;
      })
      .filter((entry: string | undefined) => entry !== undefined);
    return changes.join(", ");
  };
  const getTableComponentProps = (id: string): TableComponentProps => {
    return {
      id,
      headers: tableColumnHeadings.map((ch) => {
        return {
          index: "auditlog",
          columnName: ch.toLowerCase(),
          displayName: ch,
        };
      }),
      body: (results || [])
        .map((entry, index) => {
          const activity = getActivity(entry);
          const isTruncated = activity?.includes("truncated: true");
          const activityForDisplay = isTruncated
            ? activity.substring(0, activity.indexOf("truncated"))
            : activity;
          const activityElement = (
            <div data-testid={`activity-${index}`}>
              <p>{activityForDisplay}</p>
              <LogActivityTruncationWarning>
                {isTruncated ? "Activity report has been truncated" : ""}
              </LogActivityTruncationWarning>
            </div>
          );
          return activityForDisplay.length > 0
            ? [
                <div key={`index-${index}-1`}>{entry.timestamp}</div>,
                <div key={`index-${index}-2`}>{entry.tableName}</div>,
                <div key={`index-${index}-3`}>{getProjectName(entry)}</div>,
                <div key={`index-${index}-4`}>{entry.operation}</div>,
                <div key={`index-${index}-5`}>{entry.userId}</div>,
                <div key={`index-${index}-6`}>{activityElement}</div>,
              ]
            : [];
        })
        .filter((item) => item.length),
    };
  };

  const getTableContent = (id: string) => {
    const props = getTableComponentProps(id);
    return (
      <div className="log-table-content">
        <PaginationBar
          index="auditlog"
          totalCount={resultCount}
          page={page}
          limit={limit}
        />
        <TableComponent {...props} />
        <PaginationBar
          index="auditlog"
          totalCount={resultCount}
          page={page}
          limit={limit}
        />
      </div>
    );
  };
  return (
    <LogViewerContainer>
      <LogTableHeader>
        <FilterBar config={AuditLog.getFilterConfig()} />
      </LogTableHeader>
      <LogTableContainer>
        {!loading && (
          <LogTableScrollingContainer>
            {getTableContent("1")}
          </LogTableScrollingContainer>
        )}
        {loading && <BigSpinner data-testid="spinner" />}
      </LogTableContainer>
      <LogAlertSettingsTitle>Alert settings</LogAlertSettingsTitle>
      <LogAlertSettings />
      <LogAlertSettingsFooter />
    </LogViewerContainer>
  );
};
