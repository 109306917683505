import { API_ROOT } from "../globals/constants";
import { store } from "../store";
import { protectedFetch } from "./coreApiService";

/**
 * Looks up workers based on a search term.
 * @param {string} searchTerm - The term to search for workers.
 * @returns {Promise<any>} A promise that resolves to the worker lookup results.
 */
export const lookupWorkers = async (searchTerm: string) =>
  await protectedFetch(`${API_ROOT}/lookup/workers?term=${searchTerm}`);

/**
 * Fetches workers data from the API based on current filter parameters.
 * @returns {Promise<Object>} A promise that resolves to the workers data.
 */
export const getWorkers = async () => {
  const state = store.getState();
  const { filterParams } = state.filters.forIndex.workers;
  let filterString = filterParams ? `?${filterParams}` : "";
  return await protectedFetch(`${API_ROOT}/workers${filterString}`);
};

/**
 * Retrieves workers associated with a specific project
 * @param {string} id - The unique identifier of the project
 * @returns {Promise<any>}
 * A promise that resolves to the list of workers for the project
 */
export const getWorkersForProject = async (id: string) => {
  return await protectedFetch(`${API_ROOT}/workers/projects/${id}`);
};

/**
 * Retrieves a worker by their ID from the API.
 * @param {string} workerId - The unique identifier of the worker to fetch.
 * @returns {Promise<Object>} A promise that resolves to the worker data.
 * @throws {Error} If the API request fails or the worker is not found.
 */
export const getWorkerById = async (workerId: string) =>
  await protectedFetch(`${API_ROOT}/workers/${workerId}`);

/**
 * Creates a new worker by sending a POST request to the API.
 * @param {any} newWorker - The data for the new worker to be created.
 * @returns {Promise<Response>}
 * The response from the API after attempting to create the worker.
 */
export const createWorker = async (newWorker: any) => {
  return await protectedFetch(`${API_ROOT}/workers`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin":
        "https://si-admin-backend-uw2o.zeet-steady-install.zeet.app",
    },
    body: JSON.stringify(newWorker),
  });
};

/**
 * Updates a worker's information in the system.
 * @param {number}
 *  id - The unique identifier of the worker to update.
 * @param {any}
 * updates - An object containing the updated information for the worker.
 * @returns {Promise<Response>}
 *} A promise that resolves to the response from the API.
 */
export const updateWorker = async (id: number, updates: any) => {
  return await protectedFetch(`${API_ROOT}/workers/${id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin":
        "https://si-admin-backend-uw2o.zeet-steady-install.zeet.app",
    },
    body: JSON.stringify(updates),
  });
};

/**
 * Deletes a worker with the specified ID
 * @param {number}
 * id - The unique identifier of the worker to be deleted
 * @returns {Promise<Response>}
 * A promise that resolves to the response from the API
 */
export const deleteWorker = async (id: number) => {
  return await protectedFetch(`${API_ROOT}/workers/${id}`, {
    method: "DELETE",
  });
};
