import React, { useEffect } from "react";
import AccordionComponent, {
  AccordionComponentProps,
} from "../Accordion/Accordion";
import { LogAlertSelector, LogAlertSelectorProps } from "./LogAlertSelector";
import { CheckboxProps } from "../Checkbox/CheckboxGroup";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { store } from "../../store";
import { saveToLocalStorage } from "../../services/localStorageService";
import { LOCAL_STORAGE_FILTERS } from "../../globals/constants";
import { addOrReplaceExclusions } from "../../store/filterSlice";
import {
  LogAlertSettingsContainer,
  LogAlertSettingsScrollingContainer,
  LogAlertSettingsSectionTitle,
} from "./LogViewer.styles";
type TableFieldAndOpsList = {
  operations: string[];
  fields: string[];
};
type TableFieldsAndOpsLists = {
  [key: string]: TableFieldAndOpsList;
};

export const defaultExclusions = {
  project_workers: {
    operations: ["Insert", "Update", "Delete"],
    fields: [],
  },
  project_dates: {
    operations: ["Insert", "Update", "Delete"],
  },
  projects: {
    operations: ["Insert", "Update", "Delete"],
  },
  project_invites: {
    operations: ["Insert", "Update", "Delete"],
  },
  shift_invites: {
    operations: ["Insert", "Update", "Delete"],
  },
};

export const LogAlertSettings = () => {
  const tableFields: TableFieldsAndOpsLists = {
    projects: {
      operations: ["Insert", "Update", "Delete"],
      fields: [
        "user_id",
        "project_name",
        "reference_number",
        "description",
        "address",
        "unit",
        "site_requirement",
        "poc_name",
        "poc_phone",
        "poc_email",
        "other_manufacturer",
        "total_cost_per_hour_cents",
        "total_cost_per_hour_currency",
        "created_at",
        "updated_at",
        "total_hours",
        "badging_required",
        "badging_requirements",
        "market_id",
        "cancelled_at",
        "project_filled_at",
        "wants_replacements",
        "start_eq_project_address",
        "foreman_id",
      ],
    },
    project_invites: {
      operations: ["Insert", "Update", "Delete"],
      fields: [
        "worker_id",
        "project_id",
        "created_at",
        "updated_at",
        "admin_notes",
        "status",
        "no_other_invites",
      ],
    },
    shift_invites: {
      operations: ["Insert", "Update", "Delete"],
      fields: [
        "worker_id",
        "project_worker_id",
        "status",
        "shift_rate_cents",
        "shift_rate_currency",
        "created_at",
        "updated_at",
        "project_invite_id",
      ],
    },
    project_dates: {
      operations: ["Insert", "Update", "Delete"],
      fields: [
        "starts_at",
        "ends_at",
        "project_id",
        "created_at",
        "updated_at",
        "timezone",
        "cancelled_at",
      ],
    },
    project_workers: {
      operations: ["Insert", "Update", "Delete"],
      fields: [
        "worker_type_id",
        "total_amount",
        "filled_amount",
        "created_at",
        "updated_at",
        "project_date_id",
        "cancelled_at",
      ],
    },
  };
  const exclusions: TableFieldsAndOpsLists = useAppSelector(
    (state: any) => state.filters.forIndex["auditlog"]?.logAlertExclusions
  );
  const dispatch = useAppDispatch();
  useEffect(() => {
    const unsubscribe = store.subscribe(() => {
      saveToLocalStorage(LOCAL_STORAGE_FILTERS, store.getState().filters);
    });
    return unsubscribe;
  }, []);

  const getInitialValues = (
    key: string,
    section: { operations: string[]; fields: string[] }
  ): { operations: string[]; fields: string[] } => {
    //get exclusions for this key
    const exclusionsForTable = exclusions[key] || {};
    const opExclusions = exclusionsForTable["operations"] || [];
    const fieldExclusions = exclusionsForTable["fields"] || [];
    return {
      operations: section.operations
        .map((item: string, index: number) => {
          return opExclusions.includes(item) ? "-1" : `${index}`;
        })
        .filter((item) => item !== "-1"),
      fields: section.fields
        .map((item: string, index: number) => {
          return fieldExclusions.includes(item) ? "-1" : `${index}`;
        })
        .filter((item) => item !== "-1"),
    };
  };

  const onLogAlertChanged = (newValue: { name: string; value: string[] }) => {
    //Update the exclusions data and save it
    const tableName = newValue.name.split("-")[0];
    const section = newValue.name.split("-")[1];
    if (section !== "operations" && section !== "fields") {
      return;
    }
    let updatedExclusions = structuredClone(exclusions);
    let updatedExclusionsForTable: TableFieldAndOpsList =
      updatedExclusions[tableName] || {};
    const valuesForTable = tableFields[tableName];
    const valuesForSection = valuesForTable[section];
    const exclusionsForSection = valuesForSection
      .map((val: string, index: number) => {
        return newValue.value.includes(`${index}`) ? "" : val;
      })
      .filter((item) => item.length > 0);
    updatedExclusionsForTable[section] = exclusionsForSection;
    updatedExclusions[tableName] = updatedExclusionsForTable;
    dispatch(
      addOrReplaceExclusions({
        index: "auditlog",
        exclusions: updatedExclusions,
      })
    );
  };

  //This converts an array of strings into props for a section
  //with checkboxes
  const getCheckboxGroupComponentPropsFromSection = (
    section: "operations" | "fields",
    data: TableFieldAndOpsList
  ): CheckboxProps[] => {
    const entries = data[section];
    return entries.map((entry: string, index: number) => {
      return {
        value: `${index}`,
        displayName: entry,
      };
    });
  };

  const getLogAlertSelectorProps = (
    key: string,
    section: {
      operations: string[];
      fields: string[];
    }
  ): LogAlertSelectorProps => {
    const initialValue = getInitialValues(key, section);
    return {
      operations: {
        name: `${key}-operations`,
        items: getCheckboxGroupComponentPropsFromSection("operations", section),
        defaultValue: initialValue.operations,
      },
      fields: {
        name: `${key}-fields`,
        items: getCheckboxGroupComponentPropsFromSection("fields", section),
        defaultValue: initialValue.fields,
      },
      onChange: onLogAlertChanged,
    };
  };
  //This returns a component with checkboxes for a single table
  const getContent = (key: string) => {
    const section = tableFields[key];
    const logAlertSelectorProps = getLogAlertSelectorProps(key, section);
    return <LogAlertSelector {...logAlertSelectorProps} />;
  };
  const getSections = () => {
    return Object.keys(tableFields).map((key: string) => {
      return {
        triggerElement: (
          <LogAlertSettingsSectionTitle>{key}</LogAlertSettingsSectionTitle>
        ),
        content: getContent(key),
      };
    });
  };
  const accordionProps: AccordionComponentProps = {
    sections: getSections(),
    options: {
      multiple: true,
      collapsible: true,
    },
  };
  return (
    <LogAlertSettingsContainer>
      <LogAlertSettingsScrollingContainer>
        <AccordionComponent {...accordionProps} />
      </LogAlertSettingsScrollingContainer>
    </LogAlertSettingsContainer>
  );
};
