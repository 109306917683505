import React, { useState } from "react";
import { LogsContainer } from "./Logs.styles";
import { LogViewer } from "../../components/LogViewer/LogViewer";

export const Logs = () => {
  return (
    <LogsContainer>
      <LogViewer />
    </LogsContainer>
  );
};
