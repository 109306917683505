/* eslint-disable max-len */
import React, { useEffect, useState } from "react";
const moment = require("moment-timezone");
import { NavLink } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  AccordionTriggerBody,
  AccordionTriggerHeading,
  AccordionTriggerTitle,
  DashboardContainer,
} from "./Dashboard.styles";
import { setTitle } from "../../services/titleService";
import BigSpinner from "../../components/BigSpinner/BigSpinner";
import WorkerActivityGraph from "./Graphs/WorkerActivityGraph";
import ActiveBusinessesGraph from "./Graphs/ActiveBusinessesGraph";
import {
  getActiveBusinessData,
  getCurrentMonthWorkerPaymentData,
  getLastMonthWorkerPaymentData,
  getMarketProjectSummaries,
  getUnfilledShifts,
  getUnfilledShiftsForTodayAndTomorrow,
  getWorkerActivity,
} from "../../services/dashboardService";
import AccordionComponent, {
  AccordionComponentProps,
} from "../../components/Accordion/Accordion";
import { PaginationBar } from "../../components/PaginationBar/PaginationBar";
import {
  addIndexFilter,
  removeIndexFilter,
  replaceLocalSort,
  setLimit,
} from "../../store/filterSlice";
import {
  SegmentedControlProps,
  SegmentedControlRadius,
} from "../../components/SegmentedControl/SegmentedControl";
import {
  UnfilledShiftsDisplay,
  UnfilledShiftsProps,
} from "../../components/UnfilledShifts/UnfilledShifts";
import SortableTableComponent, {
  SortableTableComponentProps,
  sortComparator,
} from "../../components/SortableTable/SortableTable";
import { registerServiceWorker } from "../../globals/pushNotificationProcessor";

interface DataResponseCurrent {
  data: {
    current_month_worker_payments: {
      transfer_amount_cents: number;
      created_at: string;
      project_worker_id: string;
    }[];
    current_month_worker_payments_aggregate: {
      aggregate: {
        avg: {
          transfer_amount_cents: number;
          __typename: string;
        };
        sum: {
          transfer_amount_cents: number;
          __typename: string;
        };
        max: {
          transfer_amount_cents: number;
          __typename: string;
        };
        __typename: string;
      };
      __typename: string;
    };
  };
}

interface DataResponsePrevious {
  data: {
    previous_month_worker_payments_aggregate: {
      aggregate: {
        avg: {
          transfer_amount_cents: number;
          __typename: string;
        };
        sum: {
          transfer_amount_cents: number;
          __typename: string;
        };
        max: {
          transfer_amount_cents: number;
          __typename: string;
        };
        __typename: string;
      };
      __typename: string;
    };
  };
}

interface Transaction {
  transfer_amount_cents: string;
  created_at: string;
}

interface CardData {
  title: string;
  name: string;
  total: string;
  totalGrowth: string;
  totalGrowthDir?: string;
  max: string;
  maxGrowth: string;
  avg: string;
  avgGrowth: string;
}

interface MarketSummary {
  market_id: string;
  market_name: string;
  total_projects: number;
  monthly_projects: number;
  monthly_project_revenue: number;
  monthly_projects_last_year: number;
  monthly_project_revenue_last_year: number;
  previous_monthly_projects: number;
  previous_monthly_project_revenue: number;
  previous_monthly_projects_last_year: number;
  previous_monthly_project_revenue_last_year: number;
}

interface Shift {
  project_id: string;
  worker_type_id: string;
  address: string;
  market_name: string;
  poc_email: string;
  poc_name: string;
  poc_phone: string;
  position_name: string;
  project_name: string;
  cost_cents: string;
  filled_amount: number;
  total_amount: number;
  description: string;
  ends_at: string;
  starts_at: string;
  timezone: string;
}

interface MonthlyWorkerActivity {
  month_year: string;
  workers_with_completed_shifts: number;
}

interface ActiveBusinesses {
  month_posted: string;
  active_clients_count: number;
}

type UnfilledShift = {
  id: string;
  filledAmount: number;
  totalAmount: number;
  projectDate: {
    id: string;
    startsAt: string;
    endsAt: string;
    timezone: string;
    project: {
      id: string;
      projectName: string;
      market: {
        id: string;
        name: string;
        timezone: string;
      };
    };
  };
  workerType: {
    id: string;
    level: number;
    positionName: string;
  };
};

type UnfilledShifts = UnfilledShift[];

type UnfilledShiftDataByMarket = { [key: string]: UnfilledShifts };

type UnfilledShiftDataByMarketAndLevel = {
  [key: string]: { [key: string]: UnfilledShifts };
};

type TableHeader = {
  displayName: string;
  columnName: string;
  sortable?: boolean;
};

type TableHeaders = TableHeader[];

export const Dashboard = () => {
  const user: any = useAppSelector((state) => state.auth.user);
  const [loading, setLoading] = useState(true);
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });
  const dispatch = useAppDispatch();
  const [cardData, setCardData] = useState<CardData[]>([]);
  const [transactions, setTransactions] = useState<Transaction[]>([]);
  const [marketSummaries, setMarketSummaries] = useState<MarketSummary[]>([]);
  const [workerActivityData, setWorkerActivityData] = useState<
    MonthlyWorkerActivity[]
  >([]);
  const [resultCount, setResultCount] = useState(0);
  const [activeBusinessesData, setActiveBusinessesData] = useState<
    ActiveBusinesses[]
  >([]);
  const [
    todayTomorrowUnfilledShiftsProps,
    setTodayTomorrowUnfilledShiftsProps,
  ] = useState<UnfilledShiftsProps | undefined>();
  const [unfilledShiftsProps, setUnfilledShiftsProps] = useState<
    UnfilledShiftsProps | undefined
  >();
  const filters = useAppSelector(
    (state: any) => (state.filters.forIndex["dashboard"] || {}).value || []
  );
  const localSortColumns = useAppSelector(
    (state: any) =>
      (state.filters.forIndex["dashboard"] || {}).localSortColumns || {}
  );
  const page = useAppSelector(
    (state: any) => (state.filters.forIndex["dashboard"] || {}).page || 1
  );
  const limit = useAppSelector(
    (state: any) => (state.filters.forIndex["dashboard"] || {}).limit || 25
  );

  useEffect(() => {
    registerServiceWorker();
  }, []);

  const groupUnfilledShiftsByMarket = (
    ungroupedShifts: UnfilledShifts
  ): UnfilledShiftDataByMarket => {
    const grouped = (ungroupedShifts || [])
      .sort((a: UnfilledShift, b: UnfilledShift) => {
        const aName = a.projectDate ? a.projectDate.project?.market?.name : "";
        const bName = b.projectDate ? b.projectDate.project?.market?.name : "";
        return aName < bName ? -1 : aName > bName ? 1 : 0;
      })
      .reduce((acc, current) => {
        const key = current.projectDate?.project?.market?.name || "Empty";
        //@ts-ignore
        acc[key] = acc[key] ?? [];
        //@ts-ignore
        acc[key].push(current);
        return acc;
      }, {});
    return grouped;
  };

  const groupUnfilledShiftsByMarketsAndLevel = (
    shiftsGroupedByMarket: UnfilledShiftDataByMarket
  ): UnfilledShiftDataByMarketAndLevel => {
    let result = {};
    Object.keys(shiftsGroupedByMarket).forEach((entry) => {
      const ungrouped = shiftsGroupedByMarket[entry].sort((a, b) => {
        const localTimeA = a.projectDate
          ? moment(a.projectDate.startsAt).tz(a.projectDate.timezone).valueOf()
          : moment().valueOf();
        const localTimeB = b.projectDate
          ? moment(b.projectDate.startsAt).tz(b.projectDate.timezone).valueOf()
          : moment().valueOf();
        return localTimeA - localTimeB;
      });
      const groupedValues = ungrouped.reduce((acc, current) => {
        const key = current.workerType.level;
        //@ts-ignore
        acc[key] = acc[key] ?? [];
        //@ts-ignore
        acc[key].push(current);
        return acc;
      }, {});
      //@ts-ignore
      result[entry] = groupedValues;
    });
    return result;
  };

  //When the user selects a different date range we adjust
  //the filters which causes a re-fetch of data
  const onPeriodSelect = (value: any) => {
    let filterStart = moment();
    let filterEnd = moment();
    switch (value) {
      case "tomorrow":
        {
          filterStart = moment()
            .add(1, "day")
            .startOf("day")
            .subtract(moment().utcOffset(), "minutes");
          filterEnd = moment()
            .add(1, "day")
            .endOf("day")
            .subtract(moment().utcOffset(), "minutes");
        }
        break;
      case "sevendays":
        {
          filterStart = moment()
            .startOf("day")
            .subtract(moment().utcOffset(), "minutes");
          filterEnd = moment()
            .add(6, "day")
            .endOf("day")
            .subtract(moment().utcOffset(), "minutes");
        }
        break;
      case "fourteendays":
        {
          filterStart = moment()
            .startOf("day")
            .subtract(moment().utcOffset(), "minutes");
          filterEnd = moment()
            .add(13, "day")
            .endOf("day")
            .subtract(moment().utcOffset(), "minutes");
        }
        break;
      case "thirtydays":
        {
          filterStart = moment()
            .startOf("day")
            .subtract(moment().utcOffset(), "minutes");
          filterEnd = moment()
            .add(29, "day")
            .endOf("day")
            .subtract(moment().utcOffset(), "minutes");
        }
        break;
      case "thismonth":
        {
          filterStart = moment()
            .startOf("day")
            .subtract(moment().utcOffset(), "minutes");
          filterEnd = moment()
            .endOf("month")
            .endOf("day")
            .subtract(moment().utcOffset(), "minutes");
        }
        break;
      case "nextmonth":
        {
          filterStart = moment()
            .add(1, "month")
            .startOf("month")
            .startOf("day")
            .subtract(moment().utcOffset(), "minutes");
          filterEnd = moment()
            .add(1, "month")
            .endOf("month")
            .endOf("day")
            .subtract(moment().utcOffset(), "minutes");
        }
        break;
    }
    //Remove any time related filters
    filters
      .filter((f: any) => f.column === "$projectDate.starts_at$")
      .map((f: any) => {
        dispatch(
          removeIndexFilter({
            index: "dashboard",
            idToRemove: f.id,
          })
        );
      });
    //add filters for current period
    dispatch(
      addIndexFilter({
        index: "dashboard",
        newFilter: {
          column: "$projectDate.starts_at$",
          operator: "greater_than_or_equal",
          pattern: filterStart.format("YYYY-MM-DD HH:mm:ss"),
        },
      })
    );
    dispatch(
      addIndexFilter({
        index: "dashboard",
        newFilter: {
          column: "$projectDate.starts_at$",
          operator: "less_than_or_equal",
          pattern: filterEnd.format("YYYY-MM-DD HH:mm:ss"),
        },
      })
    );
  };

  //TODO It would be much easier to simply store the segmented control value
  //e.g. 'tomorrow' 'sevendays' etc
  const getFilterSettingFromStoredFilters = (filters: any) => {
    const timeFilters = filters.filter((f: any) =>
      ["$projectDate.starts_at$", "$projectDate.ends_at$"].includes(f.column)
    );
    if (timeFilters.length !== 2) return "tomorrow";
    const startString = timeFilters.find(
      (f: any) =>
        f.column === "$projectDate.starts_at$" &&
        f.operator === "greater_than_or_equal"
    )?.pattern;
    const endString = timeFilters.find(
      (f: any) =>
        f.column === "$projectDate.starts_at$" &&
        f.operator === "less_than_or_equal"
    )?.pattern;
    if (!(startString && endString)) return "tomorrow";
    const today = moment().startOf("day");
    const startTime = moment(startString);
    const endTime = moment(endString);
    const interval = endTime.diff(startTime, "hours") + 1;
    const hoursToStart = startTime.diff(today, "hours");
    if (hoursToStart === 24 && interval === 24) {
      return "tomorrow";
    } else if (hoursToStart === 0) {
      switch (interval) {
        case 168:
          return "sevendays";
        case 336:
          return "fourteendays";
        case 720:
          return "thirtydays";
        default:
          return "thismonth";
      }
    } else {
      return "nextmonth";
    }
  };

  const getAccordionTriggerContent = (
    triggerHeading: any,
    triggerBody: any
  ) => {
    return (
      <AccordionTriggerHeading>
        <AccordionTriggerTitle>{triggerHeading}</AccordionTriggerTitle>
        <AccordionTriggerBody>{triggerBody}</AccordionTriggerBody>
      </AccordionTriggerHeading>
    );
  };

  const filterProps: SegmentedControlProps = {
    items: [
      {
        label: "Tomorrow",
        value: "tomorrow",
      },
      {
        label: "Next 7 days",
        value: "sevendays",
      },
      {
        label: "Next 14 days",
        value: "fourteendays",
      },
      {
        label: "Next 30 days",
        value: "thirtydays",
      },
      {
        label: "Current month",
        value: "thismonth",
      },
      {
        label: "Next month",
        value: "nextmonth",
      },
    ],
    onValueChange: onPeriodSelect,
    options: {
      defaultValue: getFilterSettingFromStoredFilters(filters),
      radius: SegmentedControlRadius.MEDIUM,
    },
  };

  const tableColumnHeadings: TableHeaders = [
    {
      displayName: "PW ID",
      columnName: "pwid",
      sortable: true,
    },
    {
      displayName: "Project ID",
      columnName: "projectId",
      sortable: true,
    },
    {
      displayName: "Company",
      columnName: "company",
      sortable: true,
    },
    {
      displayName: "Local Start Time",
      columnName: "localStartTime",
    },
    {
      displayName: "Project Name",
      columnName: "projectName",
      sortable: true,
    },
    {
      displayName: "Project Fill Status",
      columnName: "projectFillStatust",
    },
    {
      displayName: "Admin Shift Invite View",
      columnName: "adminShiftInviteView",
    },
    {
      displayName: "Total Workers",
      columnName: "totalWorkers",
      sortable: true,
    },
    {
      displayName: "Filled Workers",
      columnName: "filledWorkers",
      sortable: true,
    },
    {
      displayName: "Workers Needed",
      columnName: "workersNeeded",
      sortable: true,
    },
  ];

  const getShiftInviteLink = (entry: UnfilledShift) => {
    const link = `https://admin.steadyinstall.com/manage/projects/${entry.projectDate?.project?.id}/?tab=inviteWorkers`;
    return <NavLink to={link}>{link}</NavLink>;
  };

  const getLocalTime = (entry: UnfilledShift) => {
    return entry.projectDate
      ? moment(entry.projectDate.startsAt)
          .tz(entry.projectDate.timezone)
          .toLocaleString()
      : "";
  };

  const tableSortChanged = (tableId: string, column: string, order: string) => {
    let updatedSortColumns = { ...localSortColumns };
    updatedSortColumns[tableId] = `${column}-${order}`;
    dispatch(
      replaceLocalSort({
        index: "dashboard",
        localSortColumns: updatedSortColumns,
      })
    );
  };

  const idColumnSort: sortComparator = (a, b) => {
    const aVal = Array.isArray(a.props.children)
      ? a.props.children[0]
      : a.props.children;
    const bVal = Array.isArray(b.props.children)
      ? b.props.children[0]
      : b.props.children;
    if (typeof aVal === "number") {
      return (aVal - bVal) / Math.abs(aVal - bVal);
    } else {
      const aStr = String(aVal).toUpperCase();
      const bStr = String(bVal).toUpperCase();
      return aStr < bStr ? -1 : aStr > bStr ? 1 : 0;
    }
  };

  const getProjectFillStatus = (entry: UnfilledShift) => {
    return `🚧 L${entry.workerType.level} ${entry.filledAmount}/${entry.totalAmount}`;
  };

  const getTableComponentProps = (
    content: any[],
    id: string
  ): SortableTableComponentProps => {
    return {
      id,
      headers: tableColumnHeadings.map((ch) => {
        return {
          index: "dashboard",
          columnName: ch.columnName,
          displayName: ch.displayName,
          sortable: ch.sortable,
          sortOrder: undefined,
          sortComparator: idColumnSort,
        };
      }),
      body: content.map((entry, index) => {
        return [
          <div key={`index-${index}-1`}>{entry.id}</div>,
          <div key={`index-${index}-2`}>{entry.projectDate?.project?.id}</div>,
          <div key={`index-${index}-3`}>
            {entry.projectDate?.project?.user?.business?.business_name},
          </div>,
          <div key={`index-${index}-4`}>{getLocalTime(entry)}</div>,
          <div key={`index-${index}-5`}>
            {entry.projectDate?.project?.project_name},
          </div>,
          <div key={`index-${index}-6`}>{getProjectFillStatus(entry)}</div>,
          <div key={`index-${index}-7`}>{getShiftInviteLink(entry)}</div>,
          <div key={`index-${index}-8`}>{entry.totalAmount}</div>,
          <div key={`index-${index}-9`}>{entry.filledAmount}</div>,
          <div key={`index-${index}-10`}>
            {entry.totalAmount - entry.filledAmount}
          </div>,
        ];
      }),
      onTableSortChange: tableSortChanged,
      initialSort: localSortColumns[id],
    };
  };

  const getTableContent = (content: any[], id: string) => {
    return (
      <div className="accordionContent">
        <SortableTableComponent
          //@ts-ignore
          {...getTableComponentProps(content, id)}
        />
        <PaginationBar
          index="dashboard"
          totalCount={resultCount}
          page={page}
          limit={limit}
        />
      </div>
    );
  };

  const getMarketProps = (
    shifts: UnfilledShiftDataByMarketAndLevel | undefined,
    tableName: string,
    options?: AccordionComponentProps["options"]
  ): AccordionComponentProps => {
    return {
      sections: Object.entries(shifts || {}).map((elements) => {
        const market = elements[0];
        const props: AccordionComponentProps = {
          sections: Object.entries(elements[1]).map((el) => {
            const level = el[0];
            const key = `${tableName}-${market}-${level}`;
            return {
              triggerElement: getAccordionTriggerContent("LEVEL", el[0]),
              content: getTableContent(el[1], key),
            };
          }),
          options,
        };
        return {
          triggerElement: getAccordionTriggerContent("MARKET", elements[0]),
          identifier: elements[0],
          content: <AccordionComponent {...props} />,
        };
      }),
      options,
    };
  };

  const getUnfilledShiftsProps = (
    groupedUnfilledShifts: UnfilledShiftDataByMarketAndLevel | undefined
  ): UnfilledShiftsProps => {
    return {
      filterData: filterProps,
      tableData: getMarketProps(groupedUnfilledShifts, "unfilledSelectable"),
      title: "Unfilled shifts by date",
    };
  };

  const getTodayTomorrowUnfilledShiftsProps = (
    todayTomorrowGroupedUnfilledShifts:
      | UnfilledShiftDataByMarketAndLevel
      | undefined
  ): UnfilledShiftsProps => {
    return {
      filterData: {
        items: [],
      },
      tableData: getMarketProps(
        todayTomorrowGroupedUnfilledShifts,
        "unfilledTodayTomorrow",
        {
          multiple: true,
          contentClass: "AccordionItemBold",
        }
      ),
      title: "Unfilled shifts today and tomorrow",
    };
  };

  const loadPageData = async function () {
    dispatch(setLimit({ index: "dashboard", limit: -1 }));
    const unfilledShiftsForTodayAndTomorrow =
      await getUnfilledShiftsForTodayAndTomorrow();
    const todayTomorrowUnfilledGroupedByMarket = groupUnfilledShiftsByMarket(
      unfilledShiftsForTodayAndTomorrow.value
    );
    const todayTomorrowUnfilledGroupedByMarketAndLevel =
      groupUnfilledShiftsByMarketsAndLevel(
        todayTomorrowUnfilledGroupedByMarket
      );
    const todayTomorrowUnfilledProps = getTodayTomorrowUnfilledShiftsProps(
      todayTomorrowUnfilledGroupedByMarketAndLevel
    );
    setTodayTomorrowUnfilledShiftsProps(todayTomorrowUnfilledProps);
    const results = await Promise.all([
      getCurrentMonthWorkerPaymentData(),
      getLastMonthWorkerPaymentData(),
    ]);
    const formatted: CardData[] = [];
    const workerActivityResult = await getWorkerActivity();
    const activeBusinessResult = await getActiveBusinessData();
    if (workerActivityResult.data) {
      setWorkerActivityData(workerActivityResult.data.monthly_worker_activity);
    }
    if (activeBusinessResult.data) {
      setActiveBusinessesData(
        activeBusinessResult.data.monthly_active_businesses
      );
    }
    const {
      data: {
        current_month_worker_payments,
        current_month_worker_payments_aggregate: { aggregate },
      },
    } = results[0] as DataResponseCurrent;
    const {
      data: {
        previous_month_worker_payments_aggregate: { aggregate: fr_aggregate },
      },
    } = results[1] as DataResponsePrevious;
    const currentTotal = aggregate.sum.transfer_amount_cents / 100;
    const prevTotal = fr_aggregate.sum.transfer_amount_cents / 100;
    const totGrowth = currentTotal - prevTotal;
    formatted.push({
      title: `${moment().format("MMMM")} worker payments`,
      name: "current",
      avg: formatter.format(aggregate.avg.transfer_amount_cents / 100),
      avgGrowth: "",
      max: formatter.format(aggregate.max.transfer_amount_cents / 100),
      maxGrowth: "",
      total: formatter.format(currentTotal),
      totalGrowth: Math.abs(totGrowth).toFixed(0),
      totalGrowthDir: totGrowth > 0 ? "up" : "down",
    });
    setTransactions(
      current_month_worker_payments.map(
        (trc: {
          transfer_amount_cents: number;
          created_at: string;
          project_worker_id: string;
        }) => {
          return {
            created_at: moment(trc.created_at).format("DD-MM-YYYY"),
            transfer_amount_cents: formatter.format(
              trc.transfer_amount_cents / 100
            ),
          };
        }
      )
    );

    formatted.push({
      title: `${moment().subtract(1, "months").format("MMMM")} worker payments`,
      name: "previous",
      avg: formatter.format(fr_aggregate.avg.transfer_amount_cents / 100),
      avgGrowth: "",
      max: formatter.format(fr_aggregate.max.transfer_amount_cents / 100),
      maxGrowth: "",
      total: formatter.format(prevTotal),
      totalGrowth: "",
      totalGrowthDir: "",
    });

    setCardData(formatted);
    const marketProjectSummaries = await getMarketProjectSummaries();
    if (marketProjectSummaries.data) {
      setMarketSummaries(marketProjectSummaries.data.market_project_summary);
    }
    setLoading(false);
  };

  useEffect(() => {
    setTitle("Dashboard");
    loadPageData();
  }, []);

  useEffect(() => {
    try {
      getUnfilledShifts().then((result) => {
        const unfilledShiftsGroupedByMarket = groupUnfilledShiftsByMarket(
          result.value || []
        );
        const unfilledShiftsGroupedByMarketAndLevel =
          groupUnfilledShiftsByMarketsAndLevel(unfilledShiftsGroupedByMarket);
        const unfilledShiftProps = getUnfilledShiftsProps(
          unfilledShiftsGroupedByMarketAndLevel
        );
        setUnfilledShiftsProps(unfilledShiftProps);
      });
    } catch (e) {
      console.error(`Error loading unfilled shifts ${e}`);
    }
  }, [filters]);

  if (loading) return <BigSpinner />;
  return (
    <DashboardContainer className="overflow-x-hidden">
      <div className="flex gap-3 mt-3 overflow-x-scroll w-full items-center px-2 h-[200px]">
        {cardData.map((card, k) => (
          <div
            key={`card_${k}`}
            className="h-44 shadow-md border hover:shadow-lg px-4 py-4 rounded-lg w-[350px]"
          >
            <h4 className="text-sm font-medium">{card.title}</h4>
            <div className="text-2xl font-bold">{card.total}</div>
            <div className="flex justify-start gap-5 mt-2 items-center">
              <div className="flex flex-col">
                <div className="text-sm font-medium">Max</div>
                <div>{card.max}</div>
              </div>
              <div className="flex flex-col">
                <div className="text-sm font-medium">Average</div>
                <div>{card.avg}</div>
              </div>
            </div>
          </div>
        ))}
        <div className="h-44 shadow-md border hover:shadow-lg px-4 py-0 rounded-lg w-[350px]">
          <div className="graphs-container flex gap-4 mt-2">
            <WorkerActivityGraph data={workerActivityData} />
          </div>
        </div>
        <div className="h-44 shadow-md border hover:shadow-lg px-4 py-0 rounded-lg w-[350px]">
          <div className="graphs-container flex gap-4 mt-2">
            <ActiveBusinessesGraph data={activeBusinessesData} />
          </div>
        </div>
      </div>
      <UnfilledShiftsDisplay {...todayTomorrowUnfilledShiftsProps} />
      <UnfilledShiftsDisplay {...unfilledShiftsProps} />
    </DashboardContainer>
  );
};

export default Dashboard;
