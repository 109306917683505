import { API_ROOT } from "../globals/constants";
import { store } from "../store";
import { protectedFetch } from "./coreApiService";

export const lookupUsers = async (searchTerm: string) =>
  await protectedFetch(`${API_ROOT}/lookup/users?term=${searchTerm}`);

/**
 * Fetches users from the API based on current filter parameters in the store
 * @returns {Promise<Object>} A promise that resolves to the fetched user data
 */
export const getUsers = async () => {
  const state = store.getState();
  const { filterParams } = state.filters.forIndex.users;
  let filterString = filterParams ? `?${filterParams}` : "";
  return await protectedFetch(`${API_ROOT}/users${filterString}`);
};

/**
 * Retrieves a user by their ID from the API
 * @param {string} userId - The unique identifier of the user to fetch
 * @returns {Promise<Object>} A promise that resolves to the user object
 */
export const getUserById = async (userId: string) =>
  await protectedFetch(`${API_ROOT}/users/${userId}`);

/**
 * Creates a new user by sending a POST request to the API.
 * @param {any} newUser
 * The user object containing the details of the new user to be created.
 * @returns {Promise<Response>}
 * A promise that resolves to the response from the API.
 */
export const createUser = async (newUser: any) => {
  return await protectedFetch(`${API_ROOT}/users`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin":
        "https://si-admin-backend-uw2o.zeet-steady-install.zeet.app",
    },
    body: JSON.stringify(newUser),
  });
};

/**
 * Updates a user's information in the system.
 * @param {number} id - The unique identifier of the user to be updated.
 * @param {any} updates
 * An object containing the fields to be updated for the user.
 * @returns {Promise<Response>}
 * A promise that resolves to the response from the API.
 * @throws {Error} If the network request fails or the server returns an error.
 */
export const updateUser = async (id: number, updates: any) => {
  return await protectedFetch(`${API_ROOT}/users/${id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin":
        "https://si-admin-backend-uw2o.zeet-steady-install.zeet.app",
    },
    body: JSON.stringify(updates),
  });
};

/**
 * Deletes a user from the system
 * @param {number} id - The unique identifier of the user to be deleted
 * @returns {Promise<Response>}
 * A promise that resolves to the response from the API
 */
export const deleteUser = async (id: number) => {
  return await protectedFetch(`${API_ROOT}/users/${id}`, {
    method: "DELETE",
  });
};
