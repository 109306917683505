import React, { useEffect, useState } from "react";
import {
  CheckboxGroupComponent,
  CheckboxGroupComponentProps,
  CheckboxProps,
} from "../Checkbox/CheckboxGroup";

export type LogAlertSelectorProps = {
  operations: CheckboxGroupComponentProps;
  fields: CheckboxGroupComponentProps;
  onChange: (e: any) => void;
};

export const LogAlertSelector = (props: LogAlertSelectorProps) => {
  const [operationsChecked, setOperationsChecked] = useState<string[]>([]);
  const [showFields, setShowFields] = useState(false);
  const opsChanged = (data: { name: string; value: string[] }) => {
    setOperationsChecked(data.value);
    props.onChange(data);
  };
  useEffect(() => {
    setOperationsChecked(props.operations.defaultValue);
  }, []);
  useEffect(() => {
    const updateOp = props.operations.items.find(
      (item: CheckboxProps) => item.displayName === "Update"
    );
    setShowFields(
      (updateOp && operationsChecked.includes(updateOp.value)) || false
    );
  }, [operationsChecked]);
  return (
    <div>
      <h5>Alert on</h5>
      <CheckboxGroupComponent onChange={opsChanged} {...props.operations} />
      {showFields && (
        <div>
          <h5>Alert if the following fields are modified in an update</h5>
          <CheckboxGroupComponent onChange={props.onChange} {...props.fields} />
        </div>
      )}
    </div>
  );
};
