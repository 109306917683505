import React from "react";
import { Flex, TableColumnHead } from "./Table.styles";

export type TableColumnHeadProps = {
  displayName: string;
  columnName: string;
  index: any;
};

const TableColumnHeader = ({ displayName, columnName }: any) => {
  return (
    <TableColumnHead id={columnName}>
      <Flex id={columnName}>
        <span>{displayName}</span>
      </Flex>
    </TableColumnHead>
  );
};

export default TableColumnHeader;
