import { API_ROOT } from "../globals/constants";
import { store } from "../store";
import { protectedFetch } from "./coreApiService";

/**
 * Fetches worker blacklists based on a search term
 * @param {string} searchTerm - The term to search for in worker blacklists
 * @returns {Promise<any>} A promise that resolves to the worker blacklists data
 */
export const lookupWorkerBlackLists = async (searchTerm: string) =>
  await protectedFetch(
    `${API_ROOT}/lookup/workerBlackLists?term=${searchTerm}`
  );

/**
 * Fetches worker blacklists based on the current filter state.
 * @returns {Promise<Object>}
 * A promise that resolves to the worker blacklists data.
 * @throws {Error} If the network request fails or returns an error status.
 */
export const getWorkerBlackLists = async () => {
  const state = store.getState();
  const { filterParams } = state.filters.forIndex.workerBlackLists;
  let filterString = filterParams ? `?${filterParams}` : "";
  return await protectedFetch(`${API_ROOT}/workerBlackLists${filterString}`);
};

/**
 * Retrieves a worker blacklist by its ID.
 * @param {string} workerBlackListId
 * The unique identifier of the worker blacklist to retrieve.
 * @returns {Promise<object>}
 * A promise that resolves to the worker blacklist object.
 */
export const getWorkerBlackListById = async (workerBlackListId: string) =>
  await protectedFetch(`${API_ROOT}/workerBlackLists/${workerBlackListId}`);

/**
 * Creates a new worker blacklist entry.
 * @param {Object} newWorkerBlackList
 * The new worker blacklist object to be created.
 * @returns {Promise<Response>}
 * A promise that resolves to the response from the API.
 */
export const createWorkerBlackList = async (newWorkerBlackList: any) => {
  return await protectedFetch(`${API_ROOT}/workerBlackLists`, {
    method: "POST",
    headers: {
      "Access-Control-Allow-Origin":
        "https://si-admin-backend-uw2o.zeet-steady-install.zeet.app",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(newWorkerBlackList),
  });
};

/**
 * Updates a worker blacklist entry with the provided updates.
 * @param {number} id - The ID of the worker blacklist entry to update.
 * @param {any} updates - The updates to apply to the worker blacklist entry.
 * @returns {Promise<Response>}
 * A promise that resolves to the Response object from the fetch call.
 */
export const updateWorkerBlackList = async (id: number, updates: any) => {
  return await protectedFetch(`${API_ROOT}/workerBlackLists/${id}`, {
    method: "PUT",
    headers: {
      "Access-Control-Allow-Origin":
        "https://si-admin-backend-uw2o.zeet-steady-install.zeet.app",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(updates),
  });
};

/**
 * Deletes a worker blacklist entry by its ID.
 * @param {number}
 *  id - The unique identifier of the worker blacklist entry to be deleted.
 * @returns {Promise<Response>}
 * Resolves to the response from the API after
 * attempting to delete the blacklist entry.
 */
export const deleteWorkerBlackList = async (id: number) => {
  return await protectedFetch(`${API_ROOT}/workerBlackLists/${id}`, {
    method: "DELETE",
  });
};
