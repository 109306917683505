export class AuditLog {
  id: number;
  tableName: string;
  projectId: number;
  operation: string;
  timestamp: string;
  userId: string;
  previousValue: string;
  newValue: string;

  constructor(newAuditLog: IAuditLog) {
    const {
      id,
      tableName,
      projectId,
      operation,
      timestamp,
      userId,
      previousValue,
      newValue,
    } = newAuditLog;
    this.id = id;
    this.tableName = tableName;
    this.projectId = projectId;
    this.operation = operation;
    this.timestamp = timestamp;
    this.userId = userId;
    this.previousValue = previousValue;
    this.newValue = newValue;
  }
  static getFilterConfig() {
    return {
      columns: ["tableName", "projectId", "operation", "userId", "timestamp"],
      indexToFilter: "auditlog",
    };
  }
}

export interface IAuditLog {
  id: number;
  tableName: string;
  projectId: number;
  operation: string;
  timestamp: string;
  userId: string;
  previousValue: string;
  newValue: string;
}
