import { CheckboxGroup } from "@radix-ui/themes/dist/cjs/components";
import { group } from "console";
import React, { useState } from "react";

export type CheckboxProps = {
  value: string;
  displayName: string;
  checked?: boolean;
};

export type CheckboxGroupComponentProps = {
  name: string;
  items: CheckboxProps[];
  defaultValue: string[];
  options?: {
    multiple?: boolean;
  };
  onChange?: (e: any) => void;
};

export const CheckboxGroupComponent = (props: CheckboxGroupComponentProps) => {
  const [groupValue, setGroupValue] = useState<string[]>(props.defaultValue);

  const onGroupValueChanged = (value: string[]) => {
    const data = {
      name: props.name,
      value,
    };
    setGroupValue(value);
    if (props.onChange) props.onChange(data);
  };

  const getItems = () => {
    return props.items.map((item: CheckboxProps, index: number) => {
      return (
        <CheckboxGroup.Item key={`ck-item${index}`} value={item.value}>
          {item.displayName}
        </CheckboxGroup.Item>
      );
    });
  };

  return (
    <CheckboxGroup.Root
      name={props.name}
      onValueChange={onGroupValueChanged}
      value={groupValue}
    >
      {getItems()}
    </CheckboxGroup.Root>
  );
};
