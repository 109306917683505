import React from "react";
import styled from "styled-components";

export const LogViewerContainer = styled.div`
   {
    display: flex;
    flex-direction: column;
    position: fixed;
    width: 100%;
    height: 100%;
    border-radius: 4px;
  }
`;
export const LogTableContainer = styled.div`
   {
    position: absolute;
    top: 5%;
    height: 50%;
    width: 75%;
    border-style: solid;
    border-color: black;
    border-width: 1px;
    border-radius: 4px;
  }
`;

export const LogTableHeader = styled.div`
   {
    display: flex;
    flex-direction: row;
    position: absolute;
    top: 0;
    height: 5%;
    width: 75%;
    border-style: solid;
    border-color: gray;
    border-width: 1px;
    border-radius: 4px;
  }
`;

export const LogTableScrollingContainer = styled.div`
   {
    height: 100%;
    width: fit-content;
    overflow-y: scroll;
    border-radius: 4px;
  }
`;

export const LogAlertSettingsSectionTitle = styled.div`
   {
    font-size: 0.6em;
    font-weight: 560;
  }
`;

export const LogAlertSettingsTitle = styled.div`
   {
    font-weight: bold;
    position: absolute;
    top: 55%;
    height: 5%;
    width: 75%;
    padding-top: 10px;
    padding-left: 20px;
  }
`;

export const LogAlertSettingsContainer = styled.div`
   {
    position: absolute;
    top: 60%;
    height: 20%;
    width: 75%;
    border-style: solid;
    border-color: black;
    border-width: 1px;
    border-radius: 3px;
    border-radius: 4px;
  }
`;

export const LogAlertSettingsScrollingContainer = styled.div`
   {
    background-color: aquamarine;
    height: 100%;
    width: 100%;
    overflow-y: scroll;
    border-radius: 4px;
  }
`;

export const LogAlertSettingsFooter = styled.div`
   {
    position: absolute;
    top: 80%;
    height: 5%;
    width: 100%;
    padding-left: 4px;
  }
`;

export const LogActivityTruncationWarning = styled.div`
   {
    color: red;
    font-weight: bold;
  }
`;
