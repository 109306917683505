import React, { useEffect, useState, useMemo } from "react";
import { Table } from "@radix-ui/themes";
import SortableTableColumnHead, {
  SortableTableColumnHeadProps,
} from "./SortableTableHeader";

export type sortComparator = (a: any, b: any) => number;

export type SortableTableComponentProps = {
  id: string;
  headers: SortableTableColumnHeadProps[];
  body: any[][];
  initialSort?: string;
  onTableSortChange?: (tableId: string, column: string, order: string) => void;
};

const SortableTableComponent = (props: SortableTableComponentProps) => {
  const [sortBy, setSortBy] = useState(props.initialSort);
  const [sortColumn, setSortColumn] = useState<
    SortableTableColumnHeadProps | undefined
  >(undefined);
  const [sortOrder, setSortOrder] = useState("ASC");
  const [sortColumnIndex, setSortColumnIndex] = useState(0);
  const [headers, setHeaders] = useState<SortableTableColumnHeadProps[]>([]);
  const [data, setData] = useState<any[][]>([]);

  useEffect(() => {
    setHeaders(props.headers);
    setData(props.body);
  }, [props.body, props.headers]);

  const findColumnByName = (name: string) => {
    return headers.find((h) => h.columnName === name);
  };

  const onSortChange = (columnName: string, order: string) => {
    setSortBy(`${columnName}-${order}`);
    const sortColumn = findColumnByName(columnName);
    setSortColumn(sortColumn);
    setSortOrder(order);
    const sortColumnId = sortColumn ? headers.indexOf(sortColumn) : 0;
    setSortColumnIndex(sortColumnId > -1 ? sortColumnId : 0);
    if (props.onTableSortChange) {
      props.onTableSortChange(props.id, columnName, order);
    }
  };

  const defaultSort = (a: any, b: any) => {
    const difference = a - b;
    return difference / Math.abs(difference);
  };

  const sortedData = useMemo(() => {
    const comparator = sortColumn?.sortComparator
      ? sortColumn.sortComparator
      : defaultSort;
    return [...data].sort((a, b) => {
      const aElem = a[sortColumnIndex];
      const bElem = b[sortColumnIndex];
      const comparison = comparator(aElem, bElem);
      return sortOrder === "ASC" ? comparison : comparison * -1;
    });
  }, [data, sortBy]);

  const getHeaderRows = (props: SortableTableComponentProps) => {
    return props.headers.map((headerCellContent, index) => {
      const sortColumn = sortBy?.split("-")[0] || "";
      headerCellContent.sortOrder =
        sortColumn === headerCellContent.columnName
          ? sortBy?.split("-")[1]
          : "";
      return (
        <Table.ColumnHeaderCell key={`item-${index}`}>
          <SortableTableColumnHead
            onSortChange={onSortChange}
            {...headerCellContent}
          />
        </Table.ColumnHeaderCell>
      );
    });
  };
  const getBodyRows = () => {
    return (sortedData || []).map((cells, index) => (
      <Table.Row key={`item-${index}`}>
        {cells?.map((cell, index) => {
          return index === 0 ? (
            <Table.RowHeaderCell key={`item-${index}`}>
              {cell}
            </Table.RowHeaderCell>
          ) : (
            <Table.Cell key={`item-${index}`}>{cell}</Table.Cell>
          );
        })}
      </Table.Row>
    ));
  };
  return (
    <Table.Root className="TableRoot">
      <Table.Header>
        <Table.Row>{getHeaderRows(props)}</Table.Row>
      </Table.Header>
      <Table.Body>{getBodyRows()}</Table.Body>
    </Table.Root>
  );
};

export default SortableTableComponent;
