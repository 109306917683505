import { snakeToSeparateWords } from "./helpers";
const vapidKey = process.env.REACT_APP_VAPID_PUBLIC_KEY;
import { subscribe } from "../services/pushNotificationService";
//Called once we are logged in
const addSubscription = async (registration: ServiceWorkerRegistration) => {
  if (!registration) {
    return;
  }
  return registration.pushManager.subscribe({
    userVisibleOnly: true,
    applicationServerKey: vapidKey,
  });
};

export const registerServiceWorker = async () => {
  if ("serviceWorker" in navigator) {
    try {
      //check if the service worker is already registered
      const swRegistration = navigator.serviceWorker
        ? await navigator.serviceWorker.getRegistration()
        : null;
      const regActive =
        swRegistration !== null &&
        swRegistration?.active?.state === "activated";
      const register = regActive
        ? swRegistration
        : await navigator.serviceWorker.register("/serviceWorker.js");
      const existing = await register.pushManager.getSubscription();
      if (existing) {
        console.log("remove existing subscription");
        await existing.unsubscribe();
      }
      const subscription = register
        ? await addSubscription(register)
        : undefined;
      if (subscription) {
        //Pass the subscription object to our backend
        //So it can send us notifications
        const res = await subscribe(subscription);
        console.log(`Subscribe response ${res?.value}`);
      } else {
        console.error("Push notification subscription failed");
      }
    } catch (err) {
      console.error(`Error registering push notification service ${err}`);
    }
  }
};

//Process the data and return in a format the
//toast can use. Return undefined if operation
//is in exclusions or it's an update and all fields
//are excluded

const auditLogMessageKeys = [
  "id",
  "table_name",
  "project_id",
  "operation",
  "timestamp",
  "new_value",
  "previous_value",
  "user_id",
];

export const getMessageData = (
  data: any,
  exclusions: any
): string | undefined => {
  const dataKeys = Object.keys(data);
  const isAuditMessage =
    auditLogMessageKeys.length === dataKeys.length &&
    auditLogMessageKeys.every((value) => {
      return dataKeys.includes(value);
    });
  if (isAuditMessage) {
    const tableName = data["table_name"];
    const table = `${snakeToSeparateWords(tableName)}`;
    const exclusionsForTable = exclusions[tableName];
    const excludedOps = exclusionsForTable?.operations || [];
    const excludedFields = exclusionsForTable?.fields || [];
    const projectId = data["project_id"];
    const op = data["operation"];
    const opName = op === "I" ? "Insert" : op === "U" ? "Update" : "Delete";
    if (excludedOps.includes(opName)) {
      return;
    }
    const opDescription =
      op === "I"
        ? `new ${table} (project ${projectId})`
        : op === "U"
        ? `${table} record (project ${projectId}) updated`
        : `${table} record (project ${projectId}) deleted`;
    const changes: string[] = [];
    const oldRecord =
      data["previous_value"].length > 0
        ? JSON.parse(data["previous_value"])
        : {};
    const newRecord =
      data["new_value"].length > 0 ? JSON.parse(data["new_value"]) : {};
    Object.keys(newRecord).forEach((key: string) => {
      if (!excludedFields.includes(key) && oldRecord[key] !== newRecord[key]) {
        if (op === "I") {
          changes.push(`${key}: ${newRecord[key]}`);
        } else if (op === "D") {
          changes.push(`${key}: ${oldRecord[key]}`);
        } else {
          changes.push(`${key}: ${oldRecord[key]} -> ${newRecord[key]}`);
        }
      }
    });
    if (op === "I" && !changes.length) {
      return;
    }
    return `${opDescription} ${changes.join(", ")}`;
  }
};
