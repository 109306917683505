import moment from "moment";
import { API_ROOT } from "../globals/constants";
import { store } from "../store";
import { protectedFetch } from "./coreApiService";
import generateFilterQueryString from "./filterService";

export const getUnfilledShifts = async () => {
  const state = store.getState();
  const { filterParams } = state.filters.forIndex.dashboard || {};

  let filterString = filterParams ? `?${filterParams}` : "";
  return await protectedFetch(`${API_ROOT}/dashboard${filterString}`);
};

export const getUnfilledShiftsForTodayAndTomorrow = async () => {
  //this method uses static filters for today and tomorrow only
  const filterEntries = [
    {
      column: "total_amount",
      operator: "greater_than",
      pattern: "$filled_amount",
    },
    {
      column: "$projectDate.starts_at$",
      operator: "greater_than_or_equal",
      pattern: moment().startOf("day").format("YYYY-MM-DD HH:mm:ss"),
    },
    {
      column: "$projectDate.starts_at$",
      operator: "less_than_or_equal",
      pattern: moment()
        .add(1, "day")
        .endOf("day")
        .format("YYYY-MM-DD HH:mm:ss"),
    },
  ];
  const filterString = generateFilterQueryString({
    value: filterEntries,
    sortBy: { column: "", order: "" },
    page: 1,
  });
  return await protectedFetch(`${API_ROOT}/dashboard?${filterString}`);
};

export const getCurrentMonthWorkerPaymentData = async () => {
  const filterEntries = [
    {
      column: "$StripeWorkerPayment.created_at$",
      operator: "greater_than_or_equal",
      pattern: moment()
        .startOf("month")
        .startOf("day")
        .format("YYYY-MM-DD HH:mm:ss"),
    },
    {
      column: "$StripeWorkerPayment.created_at$",
      operator: "less_than_or_equal",
      pattern: moment().endOf("day").format("YYYY-MM-DD HH:mm:ss"),
    },
  ];
  const filterString = generateFilterQueryString({
    value: filterEntries,
    sortBy: { column: "", order: "" },
    page: 1,
  });
  return await protectedFetch(
    `${API_ROOT}/dashboard/payment-data-current?${filterString}`
  );
};
export const getLastMonthWorkerPaymentData = async () => {
  const filterEntries = [
    {
      column: "$StripeWorkerPayment.created_at$",
      operator: "greater_than_or_equal",
      pattern: moment()
        .subtract(1, "month")
        .startOf("month")
        .startOf("day")
        .format("YYYY-MM-DD HH:mm:ss"),
    },
    {
      column: "$StripeWorkerPayment.created_at$",
      operator: "less_than_or_equal",
      pattern: moment()
        .subtract(1, "month")
        .endOf("month")
        .endOf("day")
        .format("YYYY-MM-DD HH:mm:ss"),
    },
  ];
  const filterString = generateFilterQueryString({
    value: filterEntries,
    sortBy: { column: "", order: "" },
    page: 1,
  });
  return await protectedFetch(
    `${API_ROOT}/dashboard/payment-data-previous?${filterString}`
  );
};

export const getWorkerActivity = async () => {
  return await protectedFetch(`${API_ROOT}/dashboard/worker-activity`);
};

export const getActiveBusinessData = async () => {
  return await protectedFetch(`${API_ROOT}/dashboard/active-business-data`);
};

export const getMarketProjectSummaries = async () => {
  return await protectedFetch(`${API_ROOT}/dashboard/market-project-summaries`);
};
