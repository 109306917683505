import { API_ROOT } from "../globals/constants";
import { store } from "../store";
import { protectedFetch } from "./coreApiService";

export const getLogEntries = async () => {
  const state = store.getState();
  const { filterParams } = state.filters.forIndex.auditlog;
  let filterString = filterParams ? `?${filterParams}` : "";
  return await protectedFetch(`${API_ROOT}/auditlogs${filterString}`);
};
