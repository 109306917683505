import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

export default function Sidebar({ toggle }: { toggle: () => void }) {
  const location = useLocation();
  const navigate = useNavigate();
  const menuItems = [
    {
      icon: "/svgs/home.svg",
      to: "/",
      title: "Home",
    },
    {
      icon: "/svgs/clock.svg",
      to: "/manage/autoSchedulers",
      title: "Auto Schedulers",
    },
    {
      icon: "/svgs/building-46.svg",
      to: "/manage/businesses",
      title: "Businesses",
    },
    {
      icon: "/svgs/user.svg",
      to: "/manage/foremen",
      title: "Foremen",
    },
    {
      icon: "/svgs/manufacturers-28.svg",
      to: "/manage/manufacturers",
      title: "Manufacturers",
    },
    {
      icon: "/svgs/globe-thin.svg",
      to: "/manage/markets",
      title: "Markets",
    },
    {
      icon: "/svgs/product-2.svg",
      to: "/manage/productLines",
      title: "Product Lines",
    },
    {
      icon: "/svgs/calendar-thin.svg",
      to: "/manage/projectDates",
      title: "Project Dates",
    },
    {
      icon: "/svgs/envelope-thin.svg",
      to: "/manage/projectInvites",
      title: "Project Invites",
    },
    {
      icon: "/svgs/site-tools-34.svg",
      to: "/manage/projectSiteRequirements",
      title: "Project Site Requirements",
    },
    {
      icon: "/svgs/worker.svg",
      to: "/manage/projectWorkers",
      title: "Project Workers",
    },
    {
      icon: "/svgs/shift-invites.svg",
      to: "/manage/shiftInvites",
      title: "Shift Invites",
    },
    {
      icon: "/svgs/site-reqs.svg",
      to: "/manage/siteRequirements",
      title: "Site Requirements",
    },
    {
      icon: "/svgs/time-logs.svg",
      to: "/manage/timeLogs",
      title: "Time Logs",
    },
    {
      icon: "/svgs/worker.svg",
      to: "/manage/workers",
      title: "Workers",
    },
    {
      icon: "/svgs/blacklists.svg",
      to: "/manage/workerBlackLists",
      title: "Worker Blacklists",
    },
    {
      icon: "/svgs/worker-types.svg",
      to: "/manage/workerTypes",
      title: "Worker Types",
    },
    {
      icon: "/svgs/projects.svg",
      to: "/manage/projects",
      title: "Projects",
    },
    {
      icon: "/svgs/uploads.svg",
      to: "/manage/uploads",
      title: "Uploads",
    },
    {
      icon: "/svgs/user-30.svg",
      to: "/manage/users",
      title: "Users",
    },
    {
      icon: "/svgs/logs.svg",
      to: "/logs",
      title: "Logs",
    },
  ];
  const goTo = function (route: string) {
    // close sidebar
    toggle();
    navigate(route);
  };
  return (
    <div className="mt-10 pb-52">
      {menuItems.map(
        (menu: { to: string; title: string; icon: string }, k: number) => {
          return (
            <div
              key={`menu_${k}`}
              className={`${
                location.pathname.includes(menu.to.split("/")[2]) ||
                location.pathname === menu.to
                  ? `bg-gray-200 text-black`
                  : ``
              } px-3 py-2 hover:bg-gray-100 text-sm cursor-pointer rounded-lg 
              flex items-center gap-2`}
              onClick={() => goTo(menu.to)}
            >
              <img src={menu.icon} className="h-4 w-4" />
              {menu.title}
            </div>
          );
        }
      )}
    </div>
  );
}
