import React, { useState } from "react";
import {
  Flex,
  SortDownIcon,
  SortIcon,
  SortUpIcon,
  TableColumnHeadWithSortContainer,
} from "./SortableTable.styles";
import { sortComparator } from "./SortableTable";

export type SortableTableColumnHeadProps = {
  sortOrder: string | undefined;
  displayName: string;
  columnName: string;
  index: any;
  sortable?: boolean;
  sortComparator?: sortComparator;
  onSortChange?: (columnName: string, order: string) => void;
};

const SortableTableColumnHead = (props: SortableTableColumnHeadProps) => {
  const processSort = (sort: string) => {
    if (props.onSortChange) {
      props.onSortChange(props.columnName, sort);
    }
  };

  const toggleSort = () => {
    switch (props.sortOrder) {
      case "":
        processSort("ASC");
        break;
      case "ASC":
        processSort("DESC");
        break;
      case "DESC":
        processSort("");
        break;
    }
  };

  const showSortIcon = () => {
    switch (props.sortOrder) {
      case "":
        return <SortIcon id={props.columnName} />;
      case "ASC":
        return <SortUpIcon id={props.columnName} />;
      case "DESC":
        return <SortDownIcon id={props.columnName} />;
    }
  };
  return (
    <TableColumnHeadWithSortContainer id={props.columnName}>
      <Flex id={props.columnName}>
        <span>{props.displayName}</span>
        {props.sortable && <div onClick={toggleSort}>{showSortIcon()}</div>}
      </Flex>
    </TableColumnHeadWithSortContainer>
  );
};

export default SortableTableColumnHead;
